<template>
  <!--
    The view for the StaticResourceUploader-component.
    Used to up- and download files from the Amazon-cloud-server.
  -->
  <div class="installationDetail_Resources">
    <StaticResourceUploader :installation-id="installationId" />
  </div>
</template>

<script>
export default {
  name: "InstallationDetailResources",
  components: {
    StaticResourceUploader: () => import('@/components/StaticResourceUploader.vue')
  },
  props: {
    installationId: {
      type: String,
      required: true
    }
  }
}
</script>